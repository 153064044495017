html, body, p, h1, h2, h3, h4, h5, h6, a {
  margin: 0;
  /* font-family:"Roboto","Helvetica","Arial",sans-serif !important; */
  font-family:"Product Sans","Helvetica","Arial",sans-serif !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased !important;
  /* text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important; */
}

.ant-btn-text.ant-btn-icon-only {
  right: -10px !important;
  top: -2px !important;
  z-index: 99 !important;
}
.ant-spin-nested-loading > div.ant-spin {
  background-color: transparent;
  opacity: 0.8; 
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  min-height: 100lvh;
}

/* .ant-menu.ant-menu-submenu>.ant-menu {
  background: #2d77fa !important;
}

::selection {
  color: #fff !important;
  background: #1677ff !important;
} */

.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}
.fs-30 {
  font-size: 30px;
}
.mp0 {
  padding: 0;
  margin: 0;
}
.bold {
  font-weight: 600 !important;
}
.text-smoke {
  color: #7e8989 !important;
}
hr {
  border: none;
  border-bottom: 1px solid whitesmoke;
}
.bg-white {
  background: white;
}
.bg-concrete {
  background-color: #F2F2F2 !important;
}
.circle-icon {
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  padding: 30px 34px;
  padding-bottom: 40px;
  background: white;
  box-shadow: 0 4px 6px rgb(150 149 149 / 16%);
}
.box-2 {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 30px;
  padding-bottom: 40px;
  background: white;
  box-shadow: 0 4px 6px rgb(150 149 149 / 16%);
}
.bg-gray {
  background: #f9f9f9;
}
:root {
  --swiper-theme-color: #22b573 !important;
}
html {
  --antd-wave-shadow-color: #22b573 !important;
}
/* body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
  height: 100vh;
} */
.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}
.bgGray {
  background: #fafafa;
}
.textRight {
  text-align: right;
}
.text-blue {
  color: #2379ea !important;
}
.text-green {
  color: #22b573 !important;
}
.bg-black {
  background: black;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.size {
  height: 340px !important;
}
.btn-blue {
  background-color: #159aff !important;
  color: white !important;
}
.img-circled {
  max-width: 54px;
  min-width: 54px;
  max-height: 54px;
  min-height: 54px;
  border-radius: 500px;
  margin: 0 auto;
  display: block;
}
.img-circled-2 {
  max-width: 54px;
  min-width: 54px;
  max-height: 54px;
  min-height: 54px;
  border-radius: 500px;
}

.img-circled-3 {
  max-width: 80px;
  min-width: 80px;
  max-height: 80px;
  min-height: 80px;
  border-radius: 500px;
}
.img-circled-small {
  max-width: 36px;
  min-width: 36px;
  max-height: 36px;
  min-height: 36px;
  border-radius: 500px;
}
.text-left {
  text-align: left;
}
.custom-table tr {
  text-align: center;
  border-bottom: 1px solid #fafafa;
}
tr .border-none {
  border:none;
}
.custom-table td {
  padding: 14px 0;
}
.mobileVisible {
  display: none !important;
}
.mobileHidden {
  display: block;
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
/* 
responsive
----------
*/

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 18px !important;
    width: 100% !important;
}
}

@media only screen and (max-width: 767px) {

  .box {
    padding: 10px 24px;
    padding-bottom: 40px;
    background: white;
    box-shadow: 0 4px 6px rgb(150 149 149 / 16%);
  }
  .mobileVisible {
    display: block !important;
  }
  .mobileHidden {
    display: none !important;
  }
}